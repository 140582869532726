@font-face {
    font-family: 'Alma Synt';
    src:  url('/assets/fonts/AlmaSynt-Regular.woff2') format('woff2'),
          url('/assets/fonts/AlmaSynt-Regular.woff') format('woff'),
          url('/assets/fonts/AlmaSynt-Regular.ttf') format('truetype');
    font-style:normal;
}
@font-face {
    font-family: 'Alma Synt';
    src:  url('/assets/fonts/AlmaSynt-RegularSlant.woff2') format('woff2'),
          url('/assets/fonts/AlmaSynt-RegularSlant.woff') format('woff'),
          url('/assets/fonts/AlmaSynt-RegularSlant.ttf') format('truetype');
    font-style:italic;
}
@font-face {
    font-family: 'ABC Synt';
    src:  url('/assets/fonts/ABCSyntVariable.woff2') format('woff2'),
          url('/assets/fonts/ABCSyntVariable.woff') format('woff'),
          url('/assets/fonts/ABCSyntVariable.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'Lausanne';
    src:  url('/assets/fonts/TWKLausanne-400.woff2') format('woff2'),
          url('/assets/fonts/TWKLausanne-400.woff') format('woff'),
          url('/assets/fonts/TWKLausanne-400.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lausanne';
    src:  url('/assets/fonts/TWKLausanne-400Italic.woff2') format('woff2'),
          url('/assets/fonts/TWKLausanne-400Italic.woff') format('woff'),
          url('/assets/fonts/TWKLausanne-400Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

:root{
    --margin: 30px;
}

@media screen and (max-width:600px){
    :root{
        --margin: 20px;
    }
}

html{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
}

body{
    background:black;
    color: white;
    margin:0;
}

section{
    // max-height: -webkit-fill-available;
    min-height:calc(100dvh - var(--margin)*2);
}
section:not(.section--video, .section--largeImage){
    margin:var(--margin);
}

h1, h2, h3, h4, h5, h6{
    font-weight: normal;
    margin:0;
    font-family: 'Alma Synt';
    font-feature-settings: "ss08";
}
p, button, a{
    font-family: Lausanne, 'Lausanne', Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 120% */
    letter-spacing: 0.15px;
}
.p-sm{
    font-size: 15px;
}
@media screen and (max-width: 1000px){
    p, button, a{
        font-size: 15px;
    }
}
button{
    background:transparent;
    border:0;
    padding:0;
    color: white;
}

a{
    color: white;
    text-decoration: none;
}

ul{
    list-style: none;
    margin:0;
    padding:0;
}

.section--large-text{
    margin:var(--margin);
    min-height:calc(100dvh - var(--margin)*1.5);
}


.section--video{
    // min-height:100dvh;
    // overflow:hidden;
    // position:relative
    min-height: 56.25vw;
    height: 100dvh;
    position: relative;
}

.video-container {
    // width: calc(100dvh/1080*1920); /* Full viewport width */
    width: 100dvw;
    height: 100dvh; /* Full viewport height */
    margin: 0; /* Remove margins */
    padding: 0; /* Remove padding */
    overflow: hidden; /* Hide overflow */
}


.video-container {
    position: absolute; /* Relative positioning for the absolute iframe */
    left: 50vw;
    top: 50vh;
    transform:translate(-50%,-50%);
}

@media screen and (min-aspect-ratio: 1920/1080){

    .video-container {
        width: 100vw; /* Full viewport width */
        height: calc(100vw/1920*1080); /* Full viewport height */
    }
}


.h-lg{
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Alma Synt';
    // font-size: 125px;
    font-size: calc(7.4vw);
    font-style: normal;
    font-weight: 400;
    line-height: 85%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    span{
        letter-spacing: -0.03em;
    }
}

@media screen and (max-width: 1300px){
    .h-lg{
            // font-size: 9.615vw;
    } 
}
@media screen and (max-width: 630px){
    .h-lg{
        font-size: 45px;
        font-size: 40px;
    }
}

@media screen and (max-width: 400px){
    .h-lg{
        font-size: 34px;
    }
}

.h-md{
    font-family: 'Alma Synt';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 125% */
    letter-spacing: -0.01em;
    text-transform: uppercase;
}

nav{
    text-transform: uppercase;
    font-family: 'Alma Synt';
    z-index: 2;
    border-bottom:1px solid black;
    position:sticky;
    background-color: black;
    top:0;
    left:0;
    padding:calc(var(--margin)/2) var(--margin);
    width:calc(100vw - var(--margin)*2);
    transition:border 300ms;
    z-index:3;
    ul{
        display: flex;
        gap: 40px;
    }
    ul.home{
        transform:translate(-6.5rem);
        transition: transform 400ms;
        li:first-child{
            opacity:0;
            transition: opacity 400ms;
        }
        @media screen and (max-width: 545px){
            li:first-child{
                opacity:1;
            } 
        }
    }
    a, button{
        font-variation-settings: "slnt" 0;
        font-feature-settings: "ss08";
        transition: font-variation-settings 200ms;
        .a{
            transition: left 200ms;
            left:0;
        }
    }
    a:hover, a.active, button:hover{
        font-variation-settings: "slnt" -19;
        font-style:italic;
        .a{
            left:0;
        }
    }
    .logo{
        height: 14px;
        width: auto;
    }
    #hamburger{
        display:none;
    }
    @media screen and (max-width: 545px){
        #hamburger{
            display:block;
            cursor:pointer;
            position:absolute;
            // top:12px;

            right:var(--margin);
            // top:calc(var(--margin)/2px);
            // top:12px;
            // height: 100%;
            // overflow: auto;
            // transform: translate3d(0,0,0);
            
        }
        #hamburger:not(.active) ~ *{
            display:none;
            width:100%;
        }
        ul{
            // display: flex;
            gap: 0.4rem;
            flex-direction: column;
            // gap: 40px;
            // justify-content: space-between;
        }
        ul.home{
            transform:translate(0px);
            #hamburger{
                right:0px;
            }
        }
    }
}
nav.scrolled {
    border-bottom-color: white;
    ul.home{
        transform:translate(0rem);
        li:first-child{
            opacity:1;
        }
    }
}
footer{
    display:flex;
    gap: var(--margin);
    margin-top: 400px;
    padding:var(--margin);
    > *{
        flex:1;
        width:100%;
    }
    a{
        transition: font-variation-settings 200ms;
        font-variation-settings: "slnt" 0;
    }
    a:hover{
        font-variation-settings: "slnt" -19;
    }
    @media screen and (max-width: 1140px){
        > div:nth-child(2){
            display:none;
        }
    }
    @media screen and (max-width: 800px){
        flex-direction: column;
    }
}


*[slant] .a{
    font-variation-settings: "slnt" -19;
    // transform:translateX(10em);
    position:relative;
    // left:0.04em;
    letter-spacing:-0.05em;
    // color:red;
}

// *[interactive] span{
//     // transition: font-variation-settings 800ms;
//     transition: font-variation-settings 300ms;
//     font-variation-settings: "slnt" 0;
//     transition-delay:800ms;
// }
// *[interactive] span:hover{
//     font-variation-settings: "slnt" -19;
//     transition-delay:0ms;
//     // transition: font-variation-settings 100ms;
// }

*[interactive] span{
    letter-spacing: -0.03em !important;
    font-kerning: none;
    font-feature-settings: "ss08";
}

*[interactive] span:not(.a){
    // transition: font-variation-settings 800ms;
    // transition: font-variation-settings 300ms;
    // font-variation-settings: "slnt" 0;
    // transition-delay:800ms;
    // animation: sway ease-in-out infinite;
    font-family: 'ABC Synt', 'Alma Synt', serif;
    font-feature-settings: "ss08";
}

// *[interactive] span.ampersand{
//     font-family: 'Alma Synt', serif;
//     font-feature-settings: "ss08";
// }

@keyframes sway{
    0%{
        font-variation-settings: "slnt" calc(var(--slnt-value) * 0.5);
    }
    50%{
        font-variation-settings: "slnt" var(--slnt-value);
    }
    100%{
        font-variation-settings: "slnt" calc(var(--slnt-value) * 0.5);
    }
}

